import React from 'react';
import founder from './data/DSC02213 1.png'

const About = () => {
    return (
        <div className="About flex justify-center">
            <div className="card lg:card-side shadow-xl w-[70%] mt-12 bg-[#F4F4F4] h-full grid-cols-2 rounded-none p-7">
                <figure><img src={founder} alt="Album"/>
                </figure>
                <div className="card-body">
                    <h2 className="card-title font-analogist leading-10 text-4xl tracking-[1.55px] font-normal">Meet the
                        owner</h2>
                    <p className="font-josephin leading-6 font-light text-justify">Welcome to our handloom clothing haven!
                        I'm Sonia Singh, the proud owner and passionate curator
                        of this unique collection.Growing up, I was surrounded by the vibrant colors and intricate
                        patterns of handwoven fabrics, which sparked my lifelong love for these timeless treasures.
                        Inspired by the skilled artisans who dedicate their lives to perfecting this ancient craft, I
                        established this platform to celebrate their artistry and provide them with the recognition they
                        deserve.</p>
                    <div className="card-actions justify-end">
                        <button className="btn btn-primary">Listen</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
