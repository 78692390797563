import React from 'react';

const Navbar2 = () => {
    return (
        <div className="navbar2 mb-4">
            <div className="navbar-center bg-base-100 flex justify-center ">
                <button className="btn btn-ghost text-xl font-light  font-josephin">Home</button>
                <button className="btn btn-ghost text-xl font-light font-josephin">Products</button>
                <button className="btn btn-ghost text-xl font-light font-josephin">About Us</button>
                <button className="btn btn-ghost text-xl font-light font-josephin">Contact Us</button>
            </div>

        </div>
    );
};

export default Navbar2;
