import React from 'react';
import img1 from './data/DSC02113 1.png'
import heart from './data/heart.svg'
import img21 from './data/a206c0a02858ce417567e2db6a281ec8.jpeg'

const Grids = ({ second }) => {
    const items = [
        {
            id: 1,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 2,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 3,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 4,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 5,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 6,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 7,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 8,
            img: img1,
            name: "Kurti 1",
            price: 15208,
        },
    ]

        const items2 = [
        {
            id: 1,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 2,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 3,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 4,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 5,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 6,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 7,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        }, {
            id: 8,
            img: img21,
            name: "Kurti 1",
            price: 15208,
        },
    ]
    const using = second ? items2 : items;


    return (
        <div className="grid grid-cols-4 gap-4 mb-16">
            {using.map((item) => (
                <div className="card card-compact w-96 bg-base-100 shadow-xl">
                    <figure className="relative">
                        <img src={item.img} className="w-full" alt="kjd"/>
                        <div className="card-actions justify-end absolute top-4 right-4">
                            <button className="btn btn-circle btn-sm">
                                <img src={heart} alt="" className="w-6 h-6"/>
                                {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"*/}
                                {/*     viewBox="0 0 24 24" stroke="currentColor">*/}

                            </button>
                        </div>
                    </figure>

                    <div className="card-body">
                        <div className="flex flex-row justify-between">
                            <p className="font-josephin font-semibold"> {item.name}</p>
                            <p> {item.price}</p>
                        </div>
                        {/*<div className="card-actions">*/}
                        <button className="btn w-100 bg-[#292D32] font-josephin text-white">Add to cart</button>
                        {/*</div>*/}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Grids;
